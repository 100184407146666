import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import formStyles from '../../styles/modules/Form.module.scss'

export interface LoginData {
  email: string
  password: string
  savelogin: boolean
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  savelogin: yup.boolean(),
})

// Move this into a helper function
const getLogin = () => {
  let storedEmail = ''
  if (typeof window !== 'undefined' && window.localStorage) {
    const storage = window.localStorage
    storedEmail = storage.getItem('remember')
  }
  return storedEmail
}

const saveLogin = (email: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storage = window.localStorage
    storage.setItem('remember', email)
  }
}

const clearLogin = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storage = window.localStorage
    storage.removeItem('remember')
  }
}

interface LoginProps {
  loginRequest: (data: LoginData) => void
  loginError?: string
}

const Login: React.FC<LoginProps> = (props) => {
  const [showpwd, setShowpwd] = useState(false)
  const showPasswordToggle = () => {
    setShowpwd(!showpwd)
  }

  const defaultValues = {
    email: getLogin() || '',
    password: '',
    savelogin: true,
  }

  const { register, handleSubmit, formState, clearErrors, watch } =
    useForm<LoginData>({
      resolver: yupResolver(schema),
      defaultValues,
      mode: 'onChange',
    })

  const errors = formState.errors

  const onSubmit = async (data: LoginData) => {
    if (data.savelogin) {
      saveLogin(data.email)
    } else {
      clearLogin()
    }
    props.loginRequest(data)
  }

  const emailField = watch('email')
  const passwordField = watch('password')
  const onBlur = () => {
    if (
      emailField === defaultValues.email &&
      passwordField === defaultValues.password
    ) {
      clearErrors(['email', 'password'])
    }
  }

  return (
    <section className={`${formStyles.login}`}>
      <div className="d-flex flex-column content">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${formStyles['auth-container']}`}
          autoComplete="off"
        >
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <div className="form-group__fields">
              <input type="hidden" autoComplete="off" />
              <input
                type="email"
                className={`form-control auth-input ${
                  errors.email ? 'is-invalid' : ''
                }`}
                {...register('email')}
                defaultValue=""
                autoComplete="off"
                onBlur={onBlur}
              />
            </div>
            {errors.email && (
              <span className="form-error">{errors.email.message}</span>
            )}
          </div>

          <div className="form-group mt-4">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <span className="password-toggle" onClick={showPasswordToggle}>
              <i className={`fa ${showpwd ? 'fa-eye-slash' : 'fa-eye'} `} />
              <span className="password-toggle-label">
                {showpwd ? 'Hide' : 'Show'}
              </span>
            </span>
            <div className="form-group__fields">
              <input
                type={showpwd ? 'text' : 'password'}
                className="form-control auth-input auth-pwd"
                defaultValue=""
                autoComplete="off"
                {...register('password')}
                onBlur={onBlur}
              />
            </div>
            {errors.password && (
              <span className="form-error">{errors.password.message}</span>
            )}
            <div className="auth-message-container">
              {props.loginError && (
                <span className="form-group__fields--error">
                  {props.loginError}
                </span>
              )}
            </div>
          </div>

          <div className={`${formStyles['form-actions']}`}>
            <button
              type="submit"
              className="btn btn-purple auth-button mb-3"
              disabled={!formState.isValid}
              data-testid="btn-sign-in"
            >
              Sign In
            </button>

            <div className={`${formStyles['save-user']}`}>
              <input
                name="savelogin"
                type="checkbox"
                {...register('savelogin')}
              />
              <label htmlFor="save-login">
                Save my info for future log-ins
              </label>
            </div>

            <div className={`${formStyles['links']}`}>
              <a
                href="forgot-password"
                className={`${formStyles['text-link-children']}`}
              >
                Reset Password?
              </a>
              <a
                href="request-account"
                className={`${formStyles['text-link-children']}`}
              >
                Request an Account
              </a>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Login
