import React from 'react'
import Image from 'next/image'

export enum BrandSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
  LOGO = 'LOGO',
}

interface BrandProps {
  clientName: string
  size?: BrandSize
  className?: string
}

const Brand: React.FC<BrandProps> = ({
  clientName,
  size = BrandSize.MEDIUM,
  className = '',
}) => {
  const path = `/static/logos/${clientName}-on-pong-logo.svg`
  let width = 100
  let height = 100

  switch (size) {
    case BrandSize.LOGO:
      height = 48
      break
    case BrandSize.SMALL:
      width = 150
      height = 80
      break
    case BrandSize.MEDIUM:
      width = 250
      height = 100
      break
    case BrandSize.BIG:
      width = 350
      height = 250
      break
    default:
      width = 150
      height = 80
      break
  }

  return (
    <a href="/" className={`brand-container ${className}`}>
      <Image src={path} width={width} height={height} alt={clientName} />
    </a>
  )
}

export default Brand
