import Head from 'next/head'
import layoutStyles from '../../styles/modules/Layout.module.scss'
import { useAppContext } from '../../components/context/AppContext'
import { Userback } from '../scripts/Userback'
import capitalize from 'lodash/capitalize'

export enum VerticalAlign {
  TOP = 'v-top',
  MIDDLE = 'v-middle',
  BOTTOM = 'v-bottom',
}

interface LayoutProps {
  children: React.ReactNode
  title?: string
  verticalAlign?: VerticalAlign
  useBrandBackground?: boolean
  embedClass?: string
  userName?: string
  userEmail?: string
  hideUserBack?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  useBrandBackground,
  verticalAlign = VerticalAlign.TOP,
  embedClass,
  userName,
  userEmail,
  hideUserBack,
}) => {
  const { variableState } = useAppContext()
  const clientName = variableState.clientName || ''
  const brandClassName = useBrandBackground ? layoutStyles[clientName] : ''
  const ogTitle = clientName
    ? `${capitalize(variableState.clientName)} Connect`
    : 'Pong Enterprise'

  const imagePath = clientName
    ? `${clientName}_connect.jpg`
    : 'pong_enterprise.jpg'

  return (
    <div
      className={`
      ${layoutStyles.container}
      ${layoutStyles[verticalAlign]}
      ${brandClassName}
    `}
    >
      <Head>
        <title>
          {title ||
            `Welcome to ${capitalize(variableState.clientName)} onPong!`}
        </title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,initial-scale=1"
        />
        <meta name="sentry-trace" content="{{ span.toSentryTrace() }}" />
        {/* <!-- Classic Favicon --> */}
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon.ico" sizes="16x16 32x32 48x48 64x64" />

        <meta name="title" content={ogTitle} />
        <meta name="description" content="" />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:title" content={ogTitle} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`/${imagePath}`} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content={ogTitle} />
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="/" />
        <meta property="twitter:title" content={ogTitle} />
        <meta property="twitter:description" content="" />
        <meta property="twitter:image" content={`/${imagePath}`} />

        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="64x64"
          href="/favicon-64.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="128x128"
          href="/favicon-128.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href="/favicon-512.png"
        />

        {/* <!-- iOS --> */}
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-touch-icon-120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-touch-icon-152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon-180.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#724ae2" />
        <meta name="apple-mobile-web-app-title" content={ogTitle} />

        {/* <!-- Windows --> */}
        <meta name="msapplication-TileColor" content="#724ae2" />
        <meta name="msapplication-TileColor" content="windows-tile-144.png" />
        <meta name="msapplication-config" content="browserconfig.xml" />

        {/* <!-- Web App Manifest --> */}
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="application-name" content="Pong" />
        <meta name="theme-color" content="#724ae2" />
      </Head>
      <Userback
        userName={userName}
        userEmail={userEmail}
        customButton={hideUserBack}
      />
      <main className={`${embedClass} ${layoutStyles.main}`}>{children}</main>
    </div>
  )
}

export default Layout
