import styles from '../../styles/modules/Footer.module.scss'

const FooterLinks: React.FC<Record<string, unknown>> = () => {
  const year = new Date().getFullYear()
  return (
    <div className={styles.footer}>
      <span className={styles.copyright}>
        Copyright © {year} Pong Labs, LLC All rights reserved.
      </span>
      <a href="/privacy" className={styles.link}>
        Privacy Policy
      </a>
      {/*<a href="/privacy" className={styles.link}>
        中文
      </a>*/}
    </div>
  )
}

export default FooterLinks
